import { convertArrayToObject } from '../reducers/shared/entity.helper';

export const getArrayIntersection = (leftArray: any[], rightArray: any[]) => leftArray.filter((x) => rightArray.includes(x));

export const getArrayDifference = (leftArray: any[], rightArray: any[]) => leftArray.filter((x) => !rightArray.includes(x));

export const getArraySymmetricDifference = (leftArray: any[], rightArray: any[]) => leftArray.filter((x) => !rightArray.includes(x)).concat(rightArray.filter((x) => !leftArray.includes(x)));

export const mergeArrayUnique = (propertyKey: string, leftArray: any[], rightArray: any[]) => {
  const leftArr = convertArrayToObject(propertyKey, leftArray);
  const rightArr = convertArrayToObject(propertyKey, rightArray);

  return Object.values({
    ...leftArr,
    ...rightArr,
  });
};

export const getUnique = (array: any[]) => [...new Set(array)];
